import "./Terms.css";
import { useEffect } from "react";
export default function Terms() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  }
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div className="cover-terms">
        <h1 className="title-terms">الشروط والأحكام</h1>
      </div>

      <div className="content-terms">
        <p className="descs-terms">مرحبًا بكم في موقع شركة أبعاد العقارية. باستخدام هذا الموقع، فإنك توافق على الالتزام بالشروط والأحكام الموضحة أدناه. يُرجى قراءة هذه الشروط بعناية قبل استخدام الموقع.</p>
        <h1 className="titles-terms">	استخدام الموقع</h1>
        <p className="descs-terms">يجب عدم استخدام الموقع لأي أغراض غير قانونية أو بما يخالف هذه الشروط.</p>
        <p className="descs-terms">تحتفظ الشركة بالحق في تعديل أو إيقاف الموقع، أو أي جزء منه، في أي وقت دون إشعار مسبق</p>
        <p className="descs-terms">يُمنع المستخدمون من محاولة الوصول غير المصرح به إلى أنظمة الموقع</p>
        <h1 className="titles-terms">	حقوق الملكية الفكرية</h1>
        <p className="descs-terms">جميع المحتويات المعروضة على الموقع، بما في ذلك النصوص والصور والتصاميم والشعارات، مملوكة لشركة التسويق العقاري ومحمية بموجب قوانين الملكية الفكرية </p>
        <p className="descs-terms">يُحظر نسخ أو إعادة نشر أي محتوى من الموقع دون الحصول على موافقة كتابية مسبقة من الشركة</p>
        <h1 className="titles-terms">المسؤولية</h1>
        <p className="descs-terms">تقدم الشركة المعلومات على الموقع "كما هي" دون أي ضمانات</p>
        <p className="descs-terms">لا تتحمل الشركة المسؤولية عن أي أضرار مباشرة أو غير مباشرة تنشأ عن استخدام الموقع</p>
        <h1 className="titles-terms">الروابط الخارجية</h1>
        <p className="descs-terms">قد يحتوي الموقع على روابط لمواقع أخرى. نحن غير مسؤولين عن محتوى أو سياسات الخصوصية للمواقع الخارجية</p>
        <p className="descs-terms">إدراج أي روابط لا يعني الموافقة أو التوصية</p>
        <h1 className="titles-terms">سياسة الخصوصية</h1>
        <p className="descs-terms">يُرجى مراجعة سياسة الخصوصية لفهم كيفية جمع بياناتك الشخصية واستخدامها وحمايتها</p>
        <h1 className="titles-terms">تعديلات على الشروط والأحكام</h1>
        <p className="descs-terms">تحتفظ الشركة بالحق في تعديل هذه الشروط والأحكام في أي وقت دون إشعار مسبق</p>
        <p className="descs-terms">يُوصى بمراجعة هذه الصفحة بانتظام للاطلاع على أي تغييرات</p>
        <h1 className="titles-terms">القانون الواجب التطبيق</h1>
        <p className="descs-terms">تخضع هذه الشروط والأحكام وتُفسر وفقًا لقوانين البلد الذي تعمل فيه الشركة</p>
        <h1 className="titles-terms">اتصل بنا</h1>
        <p className="descs-terms">إذا كانت لديك أي استفسارات بخصوص هذه الشروط والأحكام، يُرجى التواصل معنا عبر البريد الإلكتروني أو رقم الهاتف الموجود على الموقع</p>

        <p className="descs-terms">شكرًا لزيارتكم لموقعنا، ونتمنى أن نلبي توقعاتكم دائمًا</p>
      </div>
    </>
  );
}
