import React, { useState } from "react";
import Swal from "sweetalert2"; // استيراد SweetAlert2
import "./FormContactUs.css";

export default function FormContactUs(props) {
  let stylingFontForm = {
    color: "white",
  };
  let styleInputs = {
    border: "1px solid white",
    backgroundColor: "transparent",
    color: "white",
  };
  let stylebutton = {
    backgroundColor: "white",
    color: "#0756A5",
  };

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    message: "",
    purpose: "سكني",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://admin.abaadre.com/public/api/messages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);

        // إعادة تعيين الحقول إلى القيم الافتراضية
        setFormData({
          name: "",
          address: "",
          email: "",
          phone: "",
          message: "",
          purpose: "سكني",
        });

        // رسالة نجاح باستخدام SweetAlert
        Swal.fire({
          icon: "success",
          title: "تم الإرسال بنجاح",
          text: "تم إرسال رسالتك بنجاح. شكرًا لتواصلك معنا!",
          confirmButtonText: "حسنًا",
        });
      } else {
        const error = await response.json();
        console.error("Error:", error);

        // رسالة خطأ باستخدام SweetAlert
        Swal.fire({
          icon: "error",
          title: "حدث خطأ",
          text: "عذرًا، لم يتم إرسال الرسالة. يرجى المحاولة لاحقًا.",
          confirmButtonText: "حسنًا",
        });
      }
    } catch (error) {
      console.error("Error:", error);

      // رسالة خطأ عند حدوث استثناء
      Swal.fire({
        icon: "error",
        title: "حدث خطأ",
        text: "لا يمكن استخدام الايميل او رقم الهاتف مريتن ",
        confirmButtonText: "حسنًا",
      });
    }
  };

  return (
    <>
      <form className="form-contactus" onSubmit={handleSubmit}>
        {/* name and address */}
        <div className="container-inputs">
          {/* name */}
          <div className="container-input">
            <label
              htmlFor="name"
              className="form-label"
              style={props.stylingFontForm && stylingFontForm}
            >
              الاسم
            </label>
            <input
              name="name"
              type="text"
              className="form-input-contactus"
              id="name"
              value={formData.name}
              onChange={handleChange}
              style={props.styleInputs && styleInputs}
            />
          </div>
          {/* phone */}
          <div className="container-input-left">
            <div className="container-lable">
              <label
                htmlFor="phone"
                className="form-label"
                style={props.stylingFontForm && stylingFontForm}
              >
                رقم الهاتف
              </label>
            </div>
            <input
              name="phone"
              type="text"
              className="form-input-contactus"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
              style={props.styleInputs && styleInputs}
            />
          </div>
        </div>
        {/* email */}
        <div className="container-input-full">
          <label
            htmlFor="email"
            className="form-label"
            style={props.stylingFontForm && stylingFontForm}
          >
            الايميل
          </label>
          <input
            type="email"
            name="email"
            className="form-input-full-contactus"
            id="email"
            value={formData.email}
            onChange={handleChange}
            style={props.styleInputs && styleInputs}
          />
        </div>
        {/* purpose */}
        <div className="container-input-full">
          <label
            htmlFor="purpose"
            className="form-label"
            style={props.stylingFontForm && stylingFontForm}
          >
            الغرض
          </label>
          <select
            required
            id="purpose"
            name="purpose"
            className="form-input-full-contactus"
            value={formData.purpose}
            onChange={handleChange}
            style={props.styleInputs && styleInputs}
          >
            <option value="سكني">سكني</option>
            <option value="إستثماري">إستثماري</option>
          </select>
        </div>
        {/* address */}
        <div className="container-input-full">
          <div className="container-lable">
            <label
              htmlFor="address"
              className="form-label"
              style={props.stylingFontForm && stylingFontForm}
            >
              العنوان
            </label>
          </div>
          <input
            name="address"
            type="text"
            className="form-input-full-contactus"
            id="address"
            value={formData.address}
            onChange={handleChange}
            style={props.styleInputs && styleInputs}
          />
        </div>
        {/* message */}
        <div className="container-input-full">
          <label
            htmlFor="message"
            className="form-label"
            style={props.stylingFontForm && stylingFontForm}
          >
            الرسالة
          </label>
          <textarea
            name="message"
            className="form-input-full-contactus-message"
            rows="10"
            id="message"
            value={formData.message}
            onChange={handleChange}
            style={props.styleInputs && styleInputs}
          ></textarea>
        </div>
        {/* button */}
        <button
          type="submit"
          className="btn-send-contactus"
          style={props.stylebutton && stylebutton}
        >
          تواصل معنا
        </button>
      </form>
    </>
  );
}
