import { useEffect } from "react";
import './privacy.css'
export default function Privacy() {
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Smooth scrolling animation
        });
      }
      
      
      useEffect(() => {
        scrollToTop();
      }, []);
    return(
        <>
        <div className="cover-privacy">
<h1 className="title-privacy">سياسة الخصوصية</h1>
        </div>

        <div className="content-privacy">
          <h1 className="titles-privacy">جمع المعلومات</h1>
          <p className="descs-privacy">نحن نقوم بجمع بعض المعلومات الشخصية من المستخدمين الذين يزورون موقعنا ويستخدمون خدماتنا. تشمل هذه المعلومات الاسم، وعنوان البريد الإلكتروني، ورقم الهاتف، والعنوان، ومعلومات المالية (في حالة الشراء أو البيع)</p>
          <h1 className="titles-privacy">استخدام المعلومات</h1>
          <p className="descs-privacy">سوف نستخدم المعلومات الشخصية التي نجمعها لتقديم الخدمات والمنتجات التي تهم المستخدمين. يمكننا استخدام المعلومات لتخصيص وتحسين تجربة المستخدم، وتحسين خدماتنا، وتحليل البيانات والإحصائيات، والتواصل مع المستخدمين بشأن المنتجات والخدمات الجديدة </p>
          <h1 className="titles-privacy">حماية المعلومات</h1>
          <p className="descs-privacy">نحن نتخذ إجراءات أمنية معقولة لحماية المعلومات الشخصية التي نجمعها من الوصول غير المصرح به أو الاستخدام أو الإفشاء. ومع ذلك، فإنه لا يمكننا ضمان الأمان التام، وبالتالي فإننا لا نتحمل مسؤولية أي خسارة أو ضرر ينتج عن الوصول غير المصرح به إلى المعلومات الشخصية</p>
          <h1 className="titles-privacy">مشاركة المعلومات</h1>
          <p className="descs-privacy">قد نشارك المعلومات الشخصية مع جهات خارجية محدودة إذا كان ذلك ضروريًا لتقديم الخدمات والمنتجات المطلوبة من قبل المستخدمين. على سبيل المثال، قد نشارك المعلومات مع وكلاء العقارات أو البنوك أو الشركات النقل لإتمام عملية شراء أو بيع الشقق</p>
          <h1 className="titles-privacy">ملفات تعريف الارتباط (Cookies)</h1>
          <p className="descs-privacy">قد نستخدم ملفات تعريف الارتباط (cookies) وتقنيات مماثلة لجمع معلومات غير شخصية حول المستخدمين وتحسين تجربتهم على الموقع. يمكن للمستخدم تعطيل ملفات تعريف الارتباط في إعدادات المتصفح، ولكن قد يؤدي ذلك إلى تقليل بعض وظائف الموقع</p>
          <h1 className="titles-privacy">تغييرات في سياسة الخصوصية</h1>
          <p className="descs-privacy">قد نقوم بتحديث سياسة الخصوصية بين الحين والآخر لتعكس التغييرات في ممارساتنا أو لمتطلبات القانون</p>
        </div>
        </>
    )
}